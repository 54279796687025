<template>
  <centered-layout>
    <card-with-logo>
      <template #title> {{ labels.LBL_WELCOME }} </template>

      <el-form ref="loginInForm" :model="credentials" :rules="rules">
        <el-form-item prop="email">
          <el-input
            v-model="credentials.email"
            type="email"
            prefix-icon="el-icon-message"
            :placeholder="labels.PH_EMAIL"
            @keyup.native.enter="onSubmit('loginInForm')"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="credentials.password"
            prefix-icon="el-icon-lock"
            :placeholder="labels.PH_PASSWORD"
            show-password
            @keyup.native.enter="onSubmit('loginInForm')"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="warning"
            class="btn-block"
            plain
            @click="onSubmit('loginInForm')"
          >
            {{ labels.LBL_LOGIN }}
          </el-button>
        </el-form-item>
      </el-form>

      <el-button type="text" @click="forgotPassword">
        {{ labels.LBL_FORGOT_PASSWORD }}?
      </el-button>
    </card-with-logo>
  </centered-layout>
</template>

<script>
  import { labels, rules } from "@/common";
  import Credentials from "@/models/credentials";
  import CenteredLayout from "@/components/layouts/CenteredLayout";
  import CardWithLogo from "@/components/CardWithLogo";

  export default {
    name: "Login",

    components: {
      CenteredLayout,
      CardWithLogo,
    },

    data() {
      return {
        labels,
        rules,
        credentials: new Credentials(),
      };
    },

    //   computed: {
    //     ...mapState({
    //       isBusy: (state) => state.loading,
    //     }),
    //     ...mapGetters({
    //       loggedIn: 'auth/loggedIn',
    //     }),
    //   },

    methods: {
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch("auth/login", this.credentials);
          }
        });
      },

      forgotPassword() {
        this.$router.push("/forgot-password");
      },
    },
  };
</script>
